<template>
  <div class="m-0 p-0">
    <div class="m-auto m-w-85"> <!--  style="width: 85%" -->
      <!-- 图片、简介 -->
      <div class="row mt-3 ml-0 mr-0">
        <div class=" p-0 topImg-w animate__animated animate__slideInLeft"> <!--style="width: 72%;float: left;margin-right: 1%" -->
          <v-topbg />
        </div>
        <div class="mb-0 pb-0 pr-0 comm-w"> <!--  style="width: 27%; float: right" -->
          <div class="text-left animate__animated animate__slideInRight card h-100 border-0">
            <div class="row m-0 h7 card-header bg-jj text-white border-0">
              <div class="col-8 text-left pl-0">
                <i class="fa fa-bank"></i>
                <span class="pl-2 font-weight-bold">简介</span></div>
              <div class="col-4 text-right pl-0 pr-0"><a class="small text-white" href="/company">详细>></a></div>
            </div>
            <div class="card-body p-0">
              <div class="h-100 p-3 bg-lan">
                <div class="pd-md-5 small">
                  <span class=" pl-4">{{ noticeData7.noticeTitle }}<br /></span>
                  <span class=" pl-4 mt-5" v-html="noticeData7.notice"><br /></span>
                </div>
              </div>

            </div>

          </div>
        </div>
      </div>
      <!-- 中心新闻 -->
      <div class="row m-0 mt-2">
        <div class="col-md-4 animate__animated animate__slideInLeft card p-0" style="min-height: 250px">
          <div class="row m-0 h7 card-header">
            <div class="col-6 text-left"><i class="fa fa-newspaper-o text-danger" aria-hidden="true"></i><span class="pl-2 font-weight-bold">新闻咨讯</span></div>
            <div class="col-6 text-right"><a class="pr-3 small" href="javascript:void(0);" @click="queryNewsByModular(1)">更多>></a></div>
          </div>
          <div class="card-body p-1">
            <ul class="text-left small mb-0 list-unstyled" v-for="(item, key) in newsList1" :key="key">
              <li class="m-2">
                <a href="javascript:(0);" class="text-dark" @click="getTopNewsById(item.newsId)">
                  {{ item.newsTitle }}
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-md-4 animate__animated animate__slideInUp card p-0">
          <div class="row m-0 h7 card-header">
            <div class="col-6 text-left"><i class="fa fa-rss-square text-danger" aria-hidden="true"></i><span class="pl-2 font-weight-bold">学术交流</span></div>
            <div class="col-6 text-right"><a class="pr-3 small" href="javascript:void(0);" @click="queryNewsByModular(2)">更多>></a></div>
          </div>
          <div class="card-body p-1">
            <ul class="text-left small mb-0 list-unstyled" v-for="(item, key) in newsList2" :key="key">
              <li class="m-2">
                <a href="javascript:(0);" class="text-dark" @click="getTopNewsById(item.newsId)">
                  {{ item.newsTitle }}
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-md-4 animate__animated animate__slideInRight card p-0">
          <div class="row m-0 h7 card-header">
            <div class="col-6 text-left"><i class="fa fa-bullhorn text-danger" aria-hidden="true"></i><span class="pl-2 font-weight-bold">通知公告</span></div>
            <div class="col-6 text-right"><a class="pr-3 small" ref="#">更多>></a></div>
          </div>
          <div class="card-body p-1">
            <ul class="text-left small mb-0 list-unstyled" v-for="(item, key) in newsList6" :key="key">
              <li class="m-2">
                <a href="javascript:(0);" class="text-dark" @click="getTopNewsById(item.newsId)">
                  {{ item.newsTitle }}
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <!-- 19大图 -->
      <div class="card w-100 mb-3 mt-3">
        <img src="../../../static/image/20big.png" class="d-block img-fluid" alt="...">
      </div>
      <!-- 创作研究 -->
      <div class="row m-0 mt-2">
        <div class="col-md-4 animate__animated animate__slideInLeft card p-0" style="min-height: 250px">
          <div class="row m-0 h7 card-header">
            <div class="col-6 text-left"><i class="fa fa-paint-brush text-danger"></i><span class="pl-2 font-weight-bold">创作研究</span></div>
            <div class="col-6 text-right"><a class="pr-3 small" href="javascript:void(0);" @click="queryNewsByModular(3)">更多>></a></div>
          </div>
          <div class="card-body p-1">
            <ul class="text-left small list-unstyled" v-for="(item, key) in newsList3" :key="key">
              <li class="m-2">
                <a href="javascript:(0);" class="text-dark" @click="getTopNewsById(item.newsId)">
                  {{ item.newsTitle }}
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-md-4 animate__animated animate__slideInUp card p-0">
          <div class="row m-0 h7 card-header">
            <div class="col-8 text-left"><i class="fa fa-users text-danger"></i><span class="pl-2 font-weight-bold">人才队伍建设</span></div>
            <div class="col-4 pl-0 pr-0 text-right"><a class="pr-3 small" href="javascript:void(0);" @click="queryNewsByModular(4)">更多>></a></div>
          </div>
          <div class="card-body p-1">
            <ul class="text-left small list-unstyled" v-for="(item, key) in newsList4" :key="key">
              <li class="m-2">
                <a href="javascript:(0);" class="text-dark" @click="getTopNewsById(item.newsId)">
                  {{ item.newsTitle }}
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-md-4 animate__animated animate__slideInRight card p-0">
          <div class="row m-0 h7 card-header">
            <div class="col-6 text-left"><i class="fa fa-list-alt text-danger"></i><span class="pl-2 font-weight-bold">业界动态</span></div>
            <div class="col-6 text-right"><a class="pr-3 small" href="javascript:void(0);" @click="queryNewsByModular(5)">更多>></a></div>
          </div>
          <div class="card-body p-1">
            <ul class="text-left small list-unstyled" v-for="(item, key) in newsList5" :key="key">
              <li class="m-2">
                <a href="javascript:(0);" class="text-dark" @click="getTopNewsById(item.newsId)">
                  {{ item.newsTitle }}
                </a>
              </li>
            </ul>
          </div>
        </div>

      </div>
    </div>
    <!-- 轮播 -->
    <div class="card mt-2 gundong">
      <div class="card-header row text-left font-weight-bold">
        <div class="col-8">
          艺术家天地
        </div>
        <div class="col-4 text-right"><a class="pr-3 small" href="/artist">详细>></a></div>
      </div>

      <ul class="fuel mt-2 mb-2 pause">
        <li class="sunel ml-2" v-for="(item,index) in imageData" :key="index">
          <div class="card">
            <div class="car-header p-0">
              <viewer>
                <img :src=item.imageUrl class="img-fluid" style="height: 114px; width: 198px">
              </viewer>
            </div>
            <div class="card-body  bg-secondary text-white mt-0 mb-0 pt-1 pb-1 font-weight-bold">
              {{ item.image }}
            </div>
          </div>
        </li>
        <li class="sunel ml-2">
          <div class="card">
            <div class="car-header p-0">
              <viewer>
                <img :src=imageData[0].imageUrl class="img-fluid" style="height: 114px; width: 198px">
              </viewer>
            </div>
            <div class="card-body  bg-secondary text-white mt-0 mb-0 pt-1 pb-1 font-weight-bold">
              {{ imageData[0].image }}
            </div>
          </div>
        </li>
        <li class="sunel ml-2">
          <div class="card">
            <div class="car-header p-0">
              <viewer>
                <img :src=imageData[1].imageUrl class="img-fluid" style="height: 114px; width: 198px">
              </viewer>
            </div>
            <div class="card-body  bg-secondary text-white mt-0 mb-0 pt-1 pb-1 font-weight-bold">
              {{ imageData[1].image }}
            </div>
          </div>
        </li>
        <li class="sunel ml-2">
          <div class="card">
            <div class="car-header p-0">
              <viewer>
                <img :src=imageData[2].imageUrl class="img-fluid" style="height: 114px; width: 198px">
              </viewer>
            </div>
            <div class="card-body  bg-secondary text-white mt-0 mb-0 pt-1 pb-1 font-weight-bold">
              {{ imageData[2].image }}
            </div>
          </div>
        </li>
        <li class="sunel ml-2">
          <div class="card">
            <div class="car-header p-0">
              <viewer>
                <img :src=imageData[3].imageUrl class="img-fluid" style="height: 114px; width: 198px">
              </viewer>
            </div>
            <div class="card-body  bg-secondary text-white mt-0 mb-0 pt-1 pb-1 font-weight-bold">
              {{ imageData[3].image }}
            </div>
          </div>
        </li>
        <li class="sunel ml-2">
          <div class="card">
            <div class="car-header p-0">
              <viewer>
                <img :src=imageData[4].imageUrl class="img-fluid" style="height: 114px; width: 198px">
              </viewer>
            </div>
            <div class="card-body  bg-secondary text-white mt-0 mb-0 pt-1 pb-1 font-weight-bold">
              {{ imageData[4].image }}
            </div>
          </div>
        </li>
        <li class="sunel ml-2">
          <div class="card">
            <div class="car-header p-0">
              <viewer>
                <img :src=imageData[5].imageUrl class="img-fluid" style="height: 114px; width: 198px">
              </viewer>
            </div>
            <div class="card-body  bg-secondary text-white mt-0 mb-0 pt-1 pb-1 font-weight-bold">
              {{ imageData[5].image }}
            </div>
          </div>
        </li>
        <li class="sunel ml-2">
          <div class="card">
            <div class="car-header p-0">
              <viewer>
                <img :src=imageData[6].imageUrl class="img-fluid" style="height: 114px; width: 198px">
              </viewer>
            </div>
            <div class="card-body  bg-secondary text-white mt-0 mb-0 pt-1 pb-1 font-weight-bold">
              {{ imageData[6].image }}
            </div>
          </div>
        </li>
      </ul>
    </div>
    <!-- 中心学术委员会 顾问（按年龄排序） -->
    <div class="card mt-2 ">
      <div class="card-header text-left row m-0">
        <div class="col-md-9">
          <span class="font-weight-bold">学术委员会 顾问</span>
          <span class="small">（按年龄排序）</span>
        </div>
        <div class="col-md-3 text-right"><a class="pr-3 small" href="/special">详细>></a></div>
      </div>
      <!--      <ul class="m-auto pl-0">-->
      <div class="card-body text-center p-0 m-auto w-100 row">
        <div class="col-md p-0">
          <div class="card-body pr-md-0">
            <div class="card yishujia">
              <div class="card-body p-0">
                <a href="javascripy:void(0)" @click="artistById('12439d19-5574-4148-b830-354e6a3e6ae1')"><img class="w-100" src="../../../static/image/people/wangzhong.jpg"></a>
              </div>
              <div class="card-footer bg-secondary text-white mt-0 mb-0 pt-1 pb-1 font-weight-bold">王仲</div>
            </div>
          </div>
        </div>
        <div class="col-md p-0">
          <div class="card-body pr-md-0">
            <div class="card yishujia">
              <div class="card-body p-0">
                <a href="javascripy:void(0)" @click="artistById('14441da6-00a6-4b04-a7e3-9f5aaf1506a9')"><img class="w-100" src="../../../static/image/people/sunhao.jpg"></a>
              </div>
              <div class="card-footer bg-secondary text-white mt-0 mb-0 pt-1 pb-1 font-weight-bold">孙浩</div>
            </div>
          </div>
        </div>
        <div class="col-md p-0">
          <div class="card-body pr-md-0">
            <div class="card yishujia">
              <div class="card-body p-0">
                <a href="javascripy:void(0)" @click="artistById('2d9e3517-ace2-4384-98f5-e71d371dd301')"><img class="w-100" src="../../../static/image/people/luogenxing.jpg"></a>
              </div>
              <div class="card-footer bg-secondary text-white mt-0 mb-0 pt-1 pb-1 font-weight-bold">骆根兴</div>
            </div>
          </div>
        </div>
        <div class="col-md p-0">
          <div class="card-body pr-md-0">
            <div class="card yishujia">
              <div class="card-body p-0">
                <a href="javascripy:void(0)" @click="artistById('c3c40ae5-5f82-45bd-91e1-7fbdc91667cf')"><img class="w-100" src="../../../static/image/people/chaoge.jpg"></a>
              </div>
              <div class="card-footer bg-secondary text-white mt-0 mb-0 pt-1 pb-1 font-weight-bold">朝戈</div>
            </div>
          </div>
        </div>
        <div class="col-md p-0">
          <div class="card-body pr-md-0">
            <div class="card yishujia">
              <div class="card-body p-0">
                <a href="javascripy:void(0)" @click="artistById('f67cbe03-576a-a087-88d6-43dbf9fc228d')"><img class="w-100" src="../../../static/image/people/longliyou.jpg"></a>
              </div>
              <div class="card-footer bg-secondary text-white mt-0 mb-0 pt-1 pb-1 font-weight-bold">龙力游</div>
            </div>
          </div>
        </div>
        <div class="col-md p-0 pr-0 col-h-1366">
          <div class="card-body pr-md-0">
            <div class="card yishujia">
              <div class="card-body p-0">
                <a href="javascripy:void(0)" @click="artistById('7ef73df6-47b0-4c88-931e-bf1e3e6c8f87')"><img class="w-100" src="../../../static/image/people/baijianchun.jpg"></a>
              </div>
              <div class="card-footer bg-secondary text-white mt-0 mb-0 pt-1 pb-1 font-weight-bold">白建春</div>
            </div>
          </div>
        </div>
        <div class="col-md p-0 col-h-1720">
          <div class="card-body pr-md-0">
            <div class="card yishujia">
              <div class="card-body p-0">
                <a href="javascripy:void(0)" @click="artistById('94afe014-6335-4782-840d-a3ea39355dc1')"><img class="w-100" src="../../../static/image/people/jianghao.jpg"></a>
              </div>
              <div class="card-footer bg-secondary text-white mt-0 mb-0 pt-1 pb-1 font-weight-bold">江浩</div>
            </div>
          </div>
        </div>
        <div class="col-md p-0 col-h-2100">
          <div class="card-body pr-md-0">
            <div class="card yishujia">
              <div class="card-body p-0">
                <a href="javascripy:void(0)" @click="artistById('12439d19-5574-4148-b830-354e6a3e6ae2')"><img class="w-100" src="../../../static/image/people/menmgzuomin.jpg"></a>
              </div>
              <div class="card-footer bg-secondary text-white mt-0 mb-0 pt-1 pb-1 font-weight-bold">孟佐民</div>
            </div>
          </div>
        </div>
      </div>
      <!--      </ul>-->
    </div>
    <!-- 中心学术家 -->
    <div class="row mt-3 mb-2 ml-0 mr-0">
      <div class="col-md-6 animate__animated animate__slideInLeft card p-0">
        <div class="row m-0 h7 card-header">
          <div class="col-6 text-left"><span class="pl-0 font-weight-bold">艺术家</span></div>
          <div class="col-6 text-right"><a class="pr-3 small" href="team">更多>></a></div>
        </div>
        <div class="card-body p-0 m-0">
          <div class="w-100 row m-0 p-0 pt-3">
            <div class="col m-auto p-0 zxysj" v-for="(item, index) in peoListByCms1" :key="index">
              <a class=" text-white pt-0 pb-0 small" href="javascript:void(0);" @click="artistById(item.peoId)">
                <img :src="require('../../../static/image/people/' + item.peoPhoto + '.jpg')" class="img-fluid">
              </a>
            </div>
          </div>
          <div class="w-100 row m-0 p-0 pt-3 pb-3">
            <div class="col m-auto p-0 zxysj" v-for="(item, index) in peoListByCms2" :key="index">
              <a class=" text-white pt-0 pb-0 small" href="javascript:void(0);" @click="artistById(item.peoId)">
                <img :src="require('../../../static/image/people/' + item.peoPhoto + '.jpg')" class="img-fluid">
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3 ml-md-5 animate__animated animate__slideInLeft card p-0">
        <div class="row m-0 h7 card-header bg-lan">
          <div class="col-6 text-left"><span class="pl-0 font-weight-bold">展览与链接</span></div>
<!--          <div class="col-6 text-right"><a class="pr-0 small" href="javascript:void(0);">更多>></a></div>-->
        </div>
        <div class="text-left pt-4 ">
          <a href="https://baike.baidu.com/item/%E4%B8%AD%E5%9B%BD%E7%AE%A1%E7%90%86%E7%A7%91%E5%AD%A6%E7%A0%94%E7%A9%B6%E9%99%A2/803933?fr=aladdin" class="text-dark pl-3 h6 btn w-100 text-left" target="_blank">
            中国管理科学研究院
          </a>
          <a href="https://www.sszss.com/byxw/1825.htm" class="text-dark pl-3 h6 btn w-100 text-left" target="_blank">
            著名油画家江浩先生受聘为设计学院教授并进行现场教学
          </a>
        </div>

      </div>
      <div class="col-md animate__animated animate__slideInLeft card p-0 ml-md-4">
        <div class=" m-0 h7 card-header pl-2 bg-lan">
          <div class="text-left float-left">
            <span class="pl-0 font-weight-bold">秘书处</span>
          </div>
          <div class="text-left float-left pl-1 col-h-1366">
            <span class="small">按任职时间排序</span>
          </div>
          <div class="text-right float-right"><a class="pr-0 small" href="javascript:void(0);">详细>></a></div>
        </div>
        <div class="text-left p-md-3 bg-white h-100 ">
          <div class="bg-secondary text-white pl-2 pt-5  pb-4 font-weight-bold m-auto h-100 col-hiden-1366">
            <span class="text-left">秘书长：</span>
            <br />
            <a class=" text-white pl-5 pt-0 pb-0" href="javascript:void(0);" @click="artistById('4f45d7b1-b456-a4fd-8732-d7e915c2867c')">江浩</a>
            <p />
            <span>副秘书长：</span><br />
            <a class="text-white pl-5" href="javascript:void(0);" @click="artistById('857606fe-155e-f858-17b3-44ba8a26c465')">梁燕</a><br />
            <a class="text-white pl-5 small" href="javascript:void(0);" @click="artistById('62aa08f3-4032-46a8-8255-00c4d3124bb5')">孙月龙（雪石）</a><br />
            <a class="text-white pl-5" href="javascript:void(0);" @click="artistById('dd8a9895-8fcc-4a6b-8d93-2bd2807aee5d')">陈志兴</a><br />
            <a class="text-white pl-5" href="javascript:void(0);" @click="artistById('2fe6bf2a-aa2d-4d0d-a698-2136c3d22cc8')">王朝营</a><br />
          </div>
          <div class="bg-secondary text-white h5 pl-4 pt-5 pb-3 font-weight-bold m-auto h-100 col-h-1366">
            <span class="text-left">秘书长：</span>
            <br />
            <a class=" text-white pl-5 pt-0 pb-0" href="javascript:void(0);" @click="artistById('4f45d7b1-b456-a4fd-8732-d7e915c2867c')">江浩</a>
            <p />
            <span>副秘书长：</span><br />
            <a class="text-white pl-5" href="javascript:void(0);" @click="artistById('857606fe-155e-f858-17b3-44ba8a26c465')">梁燕</a><br />
            <a class="text-white pl-5" href="javascript:void(0);" @click="artistById('62aa08f3-4032-46a8-8255-00c4d3124bb5')">孙月龙（雪石）</a><br />
            <a class="text-white pl-5" href="javascript:void(0);" @click="artistById('dd8a9895-8fcc-4a6b-8d93-2bd2807aee5d')">陈志兴</a><br />
            <a class="text-white pl-5" href="javascript:void(0);" @click="artistById('2fe6bf2a-aa2d-4d0d-a698-2136c3d22cc8')">王朝营</a><br />
            <a class="text-white pl-5" href="javascript:void(0);" @click="artistById('e764aae5-92b4-ad9b-9c44-26ae611b556b')">侯敬书</a><br />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import topbg from '../img/topbg'
export default {
  name: "main",
  components: {
    'v-topbg': topbg,
    // notice
  },
  data() {
    return {
      noticeData1: '',
      noticeData2: '',
      noticeData7: '',
      newsList1: '',
      newsList2: '',
      newsList3: '',
      newsList4: '',
      newsList5: '',
      newsList6: '',
      peoListByCms1: [],
      peoListByCms2: [],
      imageData: []
    }
  },
  mounted() {
    this.userAgentInfo();
    // this.getMothod(1, 2);
    this.getMothod(2,6);
    this.getPeoListForCms(2,1);
    this.getPeoListForCms(2,2);
    this.getImageByType(3,1);
    this.getNoticeByType(3);
  },
  methods: {
    userAgentInfo() {
      this.$http({
        method: 'get',
        url: '/api/cmsReception/getUserSystemInfo'
      })
    },
    queryNewsByModular(modular) {
      this.$router.push({
        path: '/communication/',
        query: {modularId: modular}
      })
    },
    artistById(peoId) {
      this.$router.push({
        path: '/artistByid/',
        query: { peoId: peoId}
      })
    },
    getMothod(type, num) {
      for (var i = 1; i<=num; i ++) {
        if (type === 1) this.getNoticeByType(i)
        if (type === 2) this.getTopNewsByModular(i)
      }
    },
    getNoticeByType(noticeType) {
      this.$http({
        method: 'get',
        url: '/api/cmsReception/getCMSNotice/' + noticeType
      }).then((res) =>{
        if (noticeType === 1) this.noticeData1 = res.data.data
        if (noticeType === 2) this.noticeData2 = res.data.data
        if (noticeType === 3) this.noticeData7 = res.data.data
      })
    },
    getTopNewsByModular(modular) {
      this.$http({
        method: 'get',
        url: '/api/cmsReception/getTopNewsByModular/' + modular
      }).then((res) =>{
        if (modular === 1) this.newsList1 = res.data.data
        if (modular === 2) this.newsList2 = res.data.data
        if (modular === 3) this.newsList3 = res.data.data
        if (modular === 4) this.newsList4 = res.data.data
        if (modular === 5) this.newsList5 = res.data.data
        if (modular === 6) this.newsList6 = res.data.data
      })
    },
    getTopNewsById(newId) {
      this.$router.push({
        path: '/cmsReception.html/',
        query: {newId: newId}
      })
    },
    getPeoListForCms(peoClass, pageNo) {
      this.$http({
        method: 'get',
        url: '/api/cmsReception/getPeoListForCms/' + peoClass + '/' + pageNo
      }).then((res) =>{
        if (pageNo === 1) this.peoListByCms1 = res.data.data
        if (pageNo === 2) this.peoListByCms2 = res.data.data
      })
    },
    getImageByType(imgType, isIndex) {
      this.$http({
        method: 'get',
        url: '/api/imageManager/getImgByType/' + imgType + "/" + isIndex
      }).then((res) =>{
        this.imageData = res.data.data
      })
    }
  }
}
</script>
<style scoped>
/*分辨率小于此值隐藏元素*/
@media screen and (max-width: 1550px){
  .col-h-1366 {
    visibility: hidden;
    display: none;
  }
}
@media screen and (max-width: 2100px){
  .col-h-2100 {
    visibility: hidden;
    display: none;
  }
}
@media screen and (max-width: 1720px){
  .col-h-1720 {
    visibility: hidden;
    display: none;
  }
}
@media screen and (min-width: 1550px){
  .col-hiden-1366 {
    visibility: hidden;
    display: none;
  }
}
/** 分辨率显示 **/
@media screen and (min-width: 1550px){
  .pd-md-5 {
    padding: 10px;
    font-size: 17px;
  }
}
/** 布局CSS **/
@media screen and (min-width: 720px) {
  .topImg-w {
    width: 72%;
    float: left;
    margin-right: 1%;
  }
  .comm-w {
    width: 27%;
    float: right;
  }
}

.bg-lan {
  background-color: #BADDFEFF;
}
@media screen and (min-width: 720px){
  .yishujia {
    width: 180px;
    height: 160px;
  }
}


.zxysj {
  max-width: 100px;
  min-height: 70px;
}
.gundong {
  width: 100%;
  overflow: hidden;
}
.fuel {
  width: 3850px;
  animation: move 30s linear 0s infinite normal;
}
.sunel {
  float: left;
  list-style: none;
}
@keyframes move {
  from {
    margin-left: 0;
  }
  to {
    margin-left: -2290px;
  }
}
.pause:hover {
  animation-play-state: paused;
}
.bg-jj {
  background: #4282f6
}
a.btn:hover {
  background-color: rgba(152, 151, 151, 0.78);
}
</style>