import Vue from 'vue'
import VueRouter from 'vue-router'
import main from '../views/main/main'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'main',
    component: main
  },
  {
    path: '/cmsReception.html',
    name: 'content',
    component: () => import('../views/content/content')
  },
  {
    path: '/msg',
    name: 'message',
    component: () => import('../views/application/message')
  },
  {
    path: '/newlist',
    name: 'newlist',
    component: () => import('../views/applist/newlist')
  },
  {
    path: '/communication',
    name: 'communication',
    component: () => import('../views/applist/communication')
  },
  {
    path: '/company',
    name: 'company',
    component: () => import('../views/applist/company')
  },
  {
    path: '/team',
    name: 'team',
    component: () => import('../views/applist/team')
  },
  {
    path: '/train',
    name: 'train',
    component: () => import('../views/content/train')
  },
  {
    path: '/certificate',
    name: 'certificate',
    component: () => import('../views/application/certificate')
  },
  {
    path: '/special',
    name: 'special',
    component: () => import('../views/content/special')
  },
  {
    path: '/artist',
    name: 'artist',
    component: () => import('../views/content/artist')
  },
  {
    path: '/artistByid',
    name: 'artistByid',
    component: () => import('../views/content/artistByid')
  },
  {
    path: '/leader',
    name: 'leader',
    component: () => import('../views/applist/leader')
  },
  {
    path: '/funcDep',
    name: 'funcDep',
    component: () => import('../views/applist/functionalDepartment  ')
  },
  {
    path: '/leadership',
    name: 'leadership',
    component: () => import('../views/applist/leadership')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
