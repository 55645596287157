import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.min'
import 'animate.css/animate.min.css'
import '../static/awesome/css/font-awesome.min.css'
import BaiduMap from 'vue-baidu-map'
import $ from 'jquery'
import router from './router'
import axios from 'axios'
import Viewer from "v-viewer";
import 'viewerjs/dist/viewer.css'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

Vue.use(Viewer)
Viewer.setDefaults({
  Options: {
    'inline': false,
    'button': false,
    'navbar': false,
    'title': false,
    'toolbar': false,
    'tooltip': false,
    'movable': false,
    'zoomable': false,
    'rotatable': false,
    'scalable': false,
    'transition': false,
    'fullscreen': false,
    'keyboard': false }
})

Vue.config.productionTip = false
Vue.prototype.$ = $
Vue.use(VueRouter)
Vue.prototype.$http = axios
Vue.use(BaiduMap,'NdRAyKyWp0r32SpWmvZtFBsLgyMyTk0e')

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
