<template>
  <div class="m-auto">
    <div class="row ml-0 mr-0 mt-4 mb-2 p-0 bg">
      <div class="col-md-9 col-sm-12 animate__animated animate__slideInLeft pl-0 pr-0">
        <img src="../../../static/image/top.png" class="d-block img-fluid pt-2 pb-2" alt="..."></div>
      <div class="col-md-3 flex-sm-wrap text-right align-self-center animate__animated animate__slideInRight text-white d-none d-md-block small">
        <a class="text-white" href="#" data-toggle="tooltip" data-placement="left" data-html="true" title="<img class=w-50 src=https://camsadc.com/img/erweima.d3ee87d8.png>">
          <i class="fa fa-weixin pr-3" aria-hidden="true"> 关注我们 </i>
        </a>
      </div>
    </div>
    <nav class="navbar navbar-expand-lg navbar-light p-0  d-none d-md-block" style="background-color: #e3f2fd;background: #4282f6;height: 56px">
<!--      <a class="navbar-brand mr-5 animate__animated animate__slideInDown text-white" href="#">中国管理科学研究院艺术发展中心</a>-->
<!--      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">-->
<!--        <span class="navbar-toggler-icon"></span>-->
<!--      </button>-->

      <div class="collapse navbar-collapse h-100 text-center" id="navbarSupportedContent" style="line-height: 40px">
        <ul class="navbar-nav w-100 h-100 font-weight-bold">
          <li class="nav-item nav-hover" style="width: 10%">
            <a class="nav-link animate__animated animate__slideInDown text-white" href="/">首页 <span class="sr-only">(current)</span></a>
          </li>
          <li class="nav-item nav-hover" style="width: 14%">
            <a class="nav-link animate__animated animate__slideInDown text-white" href="/company">关于我们</a>
          </li>
          <li class="nav-item  nav-hover" style="width: 14%">
            <a class="nav-link animate__animated animate__slideInDown text-white" href="/communication">新闻资讯</a>
          </li>
          <li class="nav-item  nav-hover" style="width: 15%">
            <a class="nav-link animate__animated animate__slideInDown text-white" href="/artist">艺术家天地</a>
          </li>
          <li class="nav-item nav-hover" style="width: 18%">
            <a class="nav-link animate__animated animate__slideInDown text-white" href="/special">学术委员会顾问</a>
          </li>
          <li class="nav-item nav-hover" style="width: 15%">
            <a class="nav-link animate__animated animate__slideInDown text-white" href="/team">艺术家</a>
          </li>
          <li class="nav-item nav-hover" style="width: 14%">
            <a class="nav-link animate__animated animate__slideInDown text-white" href="/msg">联系我们</a>
          </li>
        </ul>
<!--        <form class="form-inline my-2 my-lg-0">-->
<!--          <input class="form-control mr-sm-2" type="Search" placeholder="关键字" aria-label="Search">-->
<!--          <button class="btn btn-outline-success my-2 my-sm-0 pl-4 pr-4" type="submit">查询</button>-->
<!--        </form>-->
      </div>
    </nav>

  </div>
</template>

<script>
import $ from 'jquery'
$(function () { $("[data-toggle='tooltip']").tooltip(); });
export default {
name: "index",
  methods: {}
}
</script>

<style scoped>
.bg {

}
.nav-hover {
  height: 100%;
  margin: 0;
}
.nav-hover:hover {
  background-color: #3766bb;
}
</style>