<template>
  <div id="demo" class="carousel slide" data-ride="carousel">
    <!-- 指示符 -->
    <ul class="carousel-indicators">
      <li data-target="#demo" data-slide-to="0" class="active"></li>
      <li data-target="#demo" data-slide-to="1"></li>
      <li data-target="#demo" data-slide-to="2"></li>
    </ul>
    <!-- 轮播图片 -->
    <div class="carousel-inner">
      <div class="carousel-item active">
        <img :src=topImgData[0].imageUrl class="d-block w-100">
      </div>
      <div class="carousel-item">
        <img :src=topImgData[1].imageUrl class="d-block w-100">
      </div>
      <div class="carousel-item">
        <img :src=topImgData[2].imageUrl class="d-block w-100">
      </div>
    </div>
    <!-- 左右切换按钮 -->
    <a class="carousel-control-prev" href="#demo" data-slide="prev">
      <span class="carousel-control-prev-icon"></span>
    </a>
    <a class="carousel-control-next" href="#demo" data-slide="next">
      <span class="carousel-control-next-icon"></span>
    </a>

  </div>
</template>

<script>
export default {
name: "topbg",
  data() {
    return {
      topImgData: []
    }
  },
  mounted() {
    this.getTopImgData(2,1);
  },
  methods: {
    getTopImgData(type,index){
      this.$http({
        method: 'get',
        url: '/api/imageManager/getImgByType/' + type + '/' + index
      }).then((res) =>{
        this.topImgData = res.data.data
        console.log(res.data.data)
      })
    }
  }
}
</script>

<style scoped>

</style>