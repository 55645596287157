<template>
  <div id="app">
    <div class="row m-0 p-0">
      <div class="col-md  d-none d-md-block"></div>
      <div class="col-md-10 col-sm-12">
        <div class="m-auto m-w-85">
          <index />
        </div>
        <router-view />
      </div>
      <div class="col-md d-none d-md-block"></div>
    </div>
<!--    <div class="row m-0 p-0">-->
<!--      <div class="col-1"></div>-->
<!--      <div class="col-10">-->
<!--        <down-el />-->
<!--      </div>-->
<!--      <div class="col-1"></div>-->
<!--    </div>-->
    <v-footer />
  </div>
</template>
<script>
import index from '@/views/main/index'
import footer from "@/views/main/footer";
// import downEl from "@/views/main/downEl";

export default {
  name: "App",
  components: {
    index,
    "v-footer": footer,
    // downEl
  }
}

</script>

<style>
#app {
  font-family: Arial, Helvetica, Avenir, sans-serif;
  /*font-family: "等线 Light", Helvetica, Avenir, sans-serif;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background-image: url("../static/image/bg.png");
  background-size: 100%;
  background-repeat: repeat-x;
}

#nav {
  padding: 30px;
}
@media screen and (min-width: 720px){
  .m-w-85 {
    width: 85%;
  }
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
