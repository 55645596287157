<template>
  <footer class="blog-footer bg-secondary text-white">
    <div class="row m-0 mt-3 mb-3">
      <div class="col-md-1"></div>
      <div class="col-md-3 text-left">
        <ul style="list-style: none">
          <li>地址：北京市海淀区西小口文龙家园二里8号楼6单元801室</li>
<!--          <li>电话：13000000000</li>-->
          <li>邮箱：2535161166@qq.com</li>
        </ul>
      </div>
      <div class="col-md-1 text-md-left">
        <img src="../../../static/image/erweima.png" class="w-100">
      </div>
      <div class="col-md-7 pt-md-5 mt-3 text-right text-dark pr-5">
        <div class="mb-1">Copyright &copy; 2022.Company 中管院艺术发展中心 </div>
        <div>
          <a target="_blank" class="text-dark pr-2" href="https://beian.miit.gov.cn/"> 京ICP备2023040380号-1 </a>
          <a target="_blank" class="text-dark" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010802039740"><img src="../../../static/image/back.png" /> 京公网安备 11010802039740号</a></div>
      </div>
      <div class="col-md-1"></div>
    </div>

  </footer>
</template>

<script>
export default {
name: "footer"
}
</script>

<style scoped>
.blog-footer {
  padding: 1rem 0;
  color: #999;
  text-align: center;
  position: relative;
  z-index: 110;
  background-color: #f9f9f9;
  border-top: .05rem solid #e5e5e5;
}
.blog-footer p:last-child {
  margin-bottom: 0;
}

</style>